
"use strict"

// import componentName from './path/to/componentName'
import toggle from './navigation'
import tabbedContent from './tabbed-content'
import expand from './expand'
import videoLightbox from './video'

import './map.js'

tabbedContent()
expand('.accordion__button', (open, button, content) => {
	const openClass = 'is-open'
	open ? button.parentNode.classList.add(openClass) : button.parentNode.classList.remove(openClass)
})

videoLightbox()