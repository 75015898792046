
const button = document.querySelector('#nav-btn')
const menu = document.querySelector('.site-menu__nav-cont')


let isOpen = false
let timeout = false

function close() {
	isOpen = false
	button.classList.remove('open')
	menu.style.display = 'none'
	menu.classList.remove('menu--open')
}

function open() {
	isOpen = true
	clearTimeout(timeout)

	// toggle button state
	button.classList.add('open')

	// animate menu in
	menu.style.display = 'flex'
	menu.getBoundingClientRect()
	menu.classList.add('menu--open')

	// stop scrolling on html element
	document.documentElement.classList.add('overflow-hidden')

}

function toggle() {
	isOpen ? close() : open()
}

// add event listeners 
button.addEventListener('click', toggle)

// detect esc key
window.addEventListener('keydown', event => {
	const escKey = (event.key === 'Escape' || event.keyCode === 27)
	const isOpen = menu.classList.contains('open')
	if (isOpen && escKey) toggle()
})