function init(el) {
    const tabs = [...el.querySelectorAll('.tabbed-button')]
    
    function openTab() {
        const tabActive = el.querySelector('.tabbed-button.active')
        const tabContentActive = el.querySelector('.tabbed-content.active')
        
        const tabID = this.getAttribute('data-tab')
        const newTab = el.querySelector('#' + tabID)
        
        // Remove current active state and reinstate current
        tabContentActive.classList.remove('active');
        newTab.classList.add('active');

        // Remove current active state and reinstate current
        tabActive.classList.remove('active');
        this.classList.add('active');    
    }
    
    tabs.map(tab => tab.addEventListener('click', openTab))
}

export default () => {
    const tabbed = [...document.querySelectorAll('.tabs')]
    tabbed.map(init)
}
