function initMap() {
    const myLatLng =  { lat: 51.4674731, lng: -0.2030171 };

    var windowWidth = window.innerWidth;
    var mapCenter = { lat: 51.4675266, lng: -0.2095831 };
    if (windowWidth < 1000) {
        var mapCenter = myLatLng;
    }

    const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 16,
        center: mapCenter,
        disableDefaultUI: true,
    });

    new google.maps.Marker({
        position: myLatLng,
        map,
    });
}

window.initMap = initMap;
